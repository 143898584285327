import * as React from "react"
import { useState, useEffect } from "react"
import { Link } from "gatsby"
import NavBar from "../components/navbar"
import "../components/login.css"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import { isLoggedIn, getUser, refreshToken } from "../services/auth"
import FindForm from "../components/findform"
import { handleMap } from "../services/api"

export default function Find() {
  const [map, setMap] = useState([])
  const [maptable, setMaptable] = useState()
  useEffect(() => {
    refreshToken()
    console.log("fdasfsadfasdfasd")
    if (!isLoggedIn()) return
    ;(async () => {
      const response = await handleMap()
      setMaptable(response)
      setMap(response.current_map)
    })()
  }, [isLoggedIn])
  return (
    <div>
      <NavBar />
      <div className="center">
        <br />
        <div>
          <img
            src={`data:image/jpeg;base64,${map}`}
            width={600}
            alt="图片正在加载"
          />
          <FindForm maptable={maptable} />
        </div>
        <Link to="/login">登陆界面</Link>
      </div>
    </div>
  )
}
