import { getUser, getColumnName } from "../services/auth"
import * as React from "react"
import { useState, useEffect } from "react"
import Form from "react-bootstrap/Form"
import { Col, Button } from "react-bootstrap"
const BASE_URL = "47.99.220.122:8000"
export default function FindForm({ maptable }) {
  const [locx, setLocx] = useState()
  const [locy, setLocy] = useState()
  let location_x, location_y
  if (!maptable) {
    location_x = []
    location_y = []
  } else {
    console.log("props is here !!!!!", maptable)
    const x_set = [...Array(maptable.high_x + 1).keys()]
    const y_set = [...Array(maptable.high_y + 1).keys()]
    location_x = x_set.slice(maptable.low_x)
    location_y = y_set.slice(maptable.low_y)
  }
  const handleSubmit = event => {
    event.preventDefault()
    const accessToken = getUser().access
    ;(async () => {
      const pollsurl = BASE_URL + "/login/findclue"
      const method = "put"
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }
      const body = JSON.stringify({
        current_x: locx,
        current_y: locy,
      })
      const response = await fetch(pollsurl, { method, headers, body })
      const response_data = await response.json()
      console.log(response_data)
      if (response.ok) {
        alert(response_data.message)
        window.location.reload(false)
      } else {
        console.log("some bug in your findview")
      }
    })()
  }
  const handleChange = event => {
    if (event.target.id == "form_location_x") {
      setLocx(event.target.value)
    }
    if (event.target.id == "form_location_y") {
      setLocy(event.target.value)
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group
          as={Col}
          controlId="form_location_x"
          onChange={handleChange}
        >
          <Form.Label>行数</Form.Label>
          <Form.Control
            as="select"
            defaultValue="请选择"
            onChange={handleChange}
          >
            <option key={1}>请选择</option>
            {location_x.map((data, index) => (
              <option key={index + 1}>{getColumnName(data - 1)}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} controlId="form_location_y">
          <Form.Label>列数</Form.Label>
          <Form.Control
            as="select"
            defaultValue="请选择"
            onChange={handleChange}
          >
            <option key={1}>请选择</option>
            {location_y.map((data, index) => (
              <option key={index + 1}>{data}</option>
            ))}
          </Form.Control>
        </Form.Group>
      </Form.Row>
      <Form.Row style={{ justifyContent: "center" }}>
        <Button type="submit">提交</Button>
      </Form.Row>
    </Form>
  )
}
